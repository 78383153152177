type Options = {
  mobileOnly: boolean
}

export default function useHasBottomToolbar(options?: Options) {
  const isMobile = useIsMobile()
  const globalState = useGlobalState()

  function setBottomToolbarActive(active: boolean) {
    globalState.value.bottomToolbarActive = active
  }

  onMounted(() => {
    if (options?.mobileOnly && !isMobile.value) return
    setTimeout(() => setBottomToolbarActive(true), 300)
  })
  onUnmounted(() => {
    if (options?.mobileOnly && !isMobile.value) return
    setBottomToolbarActive(false)
  })

  watch(isMobile, () => {
    setBottomToolbarActive(isMobile.value && !!options?.mobileOnly)
  })

  return { setBottomToolbarActive }
}
